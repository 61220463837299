/* Tailwind CSS 설정 */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* 커스텀 CSS 파일 임포트 */
@import 'video.js/dist/video-js.min.css';
@import '@/styles/videojs-style.css';
@import 'react-quill/dist/quill.snow.css';
@import '@/styles/react-quill-style.css';

/* 폰트 설정 */
@font-face{
  font-family:'ChosunGs';
  src:url('@/assets/fonts/ChosunGs.ttf');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Sen';
  src: url('@/assets/fonts/Sen-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sen-Bold';
  src: url('@/assets/fonts/Sen-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url('@/assets/fonts/NotoSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: url('@/assets/fonts/NotoSans-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-Light';
  src: url('@/assets/fonts/NotoSans-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

/* 커스텀 CSS style 설정 */
/* html {
  font-family: NotoSans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
} */

body {
  font-family: NotoSans, sans-serif;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  body {
    -webkit-font-smoothing: antialiased;
  }
}

@media screen and (-moz-osx-font-smoothing: grayscale) {
  body {
    -moz-osx-font-smoothing: grayscale;
  }
}

.primary-shadow {
  box-shadow: 0 15px 50px rgba(246, 111, 77, 0.16);
}

.primary-shadow-thick {
  box-shadow: 0 10px 50px rgba(246, 111, 77, 0.336);
}

.border-shadow {
  box-shadow: 0 24px 90px rgba(192, 188, 161, 0.157);
}

.button-shadow {
  box-shadow: 0 24px 90px rgba(192, 188, 161, 0.24);
}

.button-shadow-focus {
  box-shadow: 0 24px 90px rgba(146, 144, 126, 0.24);
}

@keyframes lineAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 80%;
  }
}

.nav-item {
  position: relative;
}

.nav-item:hover:after,
.nav-item.nav-selected:after {
  content: '';
  position: absolute;
  left: 0;
  top: 22px;
  height: 0;
  border-radius: 8px;
  border: 2px solid #f66f4d;
  width: 80%;
  animation: lineAnimation 0.5s;
}

/* 모바일 뷰 */
@media (max-width: 768px) {
  @keyframes lineAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  .nav-item:hover:after,
  .nav-item.nav-selected:after {
    top: auto;
    bottom: 0;
    width: 100%;
  }
}

.button-success {
  color: #6eaf6e;
  border-color: #6eaf6e;
  background-color: white;
}

.no-drag {
  user-select: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
  box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
  color: black;
}

.font-ChosunGs{
  font-family: ChosunGs, sans-serif;
  /*font-weight: lighter;*/
}

@media print{
  display: block;
}