.ql-toolbar {
    display: block;
    border: 1px solid #2e476b !important;
}

.ql-container {
    border: 1px solid #2e476b !important;
    border-top: none !important;
}

.ql-editor {
    height: 480px;
}
